<template>
    <section class="about-product">
        <div class="container">
        <div class="section-title">
          <h1>Visitor Management Software</h1>
          <p class="pt-4" style="text-align: justify;font-weight: 500;">Everything around us is being technologically- driven from the mobile devices we view every now and then to the ticket, coffee and money vending machines for our use at our own disposal. We present you the VMS software (Visitor Management Software) to take account of every visitor to your offices. This is normally the work of a receptionist. It is deemed a necessity for large office spaced companies; considering the fact that an unmanned receptionist job can come in handy along with a receptionist by delivering service 24/7 and in keeping the records up-to-date and also stressing the fact of being a technologically advanced company.</p>
        </div>
      </div>
        </section>
</template>
<script>
export default {
    
}
</script>>
<style>
.about-product {
  padding: 200px 0px 60px 0px;
}
@media (max-width: 992px) {
  .about-product {
    padding: 130px 30px 40px 30px ;
  }
  
}
.about-product {
  background-image: url("../assets/images/background/vms.png");
  position: relative;
      -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.about-product h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    font-family: Raleway;
    letter-spacing: 2px;
    text-shadow: 0px 3px 8px #00000017;
    text-transform: uppercase;
    text-align: center;
  color: #72287a;
}
</style>