<template>
    <!-- Start About Area -->
    <section class="aboutdesc section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12 col-12">
                    <div class="content-left">
                        <img src="../assets/images/product/benefit.png" alt="#">
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-12">
                    <!-- content-1 start -->
                    <div class="content-right">
                        <h2>Benefits of using VMS</h2>
                        <h5>First Impressions</h5>
                        <div class="row">
                            <div class="col-lg-12 col-12">
                                <ul class="list">
                                    <li><i class="lni lni-checkmark-circle"></i>First Impressions are very important and considered important for businesses. The first impression any visitor gets visiting office can be positive or negative, being welcomed in by the receptionist or being greeted by no one and waiting for long period of time than expected at the front desk.</li>
                                    <li><i class="lni lni-checkmark-circle"></i>A Visitor Management System helps companies make a first good impression by welcoming visitors as they walk through your door and connecting them to whoever they are supposed to visit. This enables a positive and efficient visitor experience.</li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="about-us section desktop">
        <div class="container">
            <div class="row align-items-center">
               <div class="col-lg-8 col-md-12 col-12">
                    <!-- content-1 start -->
                    <div class="content-right">
                        <h2>Essential Features in VMS</h2>
                        <div class="row">
                            <div class="col-lg-12 col-12">
                                <ul class="list">
                                    <li><i class="lni lni-checkmark-circle"></i>Customized visitor entry process, wherein different types of visitors can be treated and recognized based on their requirements for instance a general visitor and a courier delivery person would walk in for different reasons.</li>
                                    <li><i class="lni lni-checkmark-circle"></i>Notifications via text or voice messages. The software is enabled to notify the concerned employee in the company, the visitor requires visiting. This notification can be chosen on any preferable format.</li>
                                    <li><i class="lni lni-checkmark-circle"></i>Establishing a two-way communication system and message forwarding systems in place to help communicate with the software about the absence or presence of an employee.</li>
                                    <li><i class="lni lni-checkmark-circle"></i>Simplify paperwork, by signing into the VMS run software device, and easily submit NDA’s or look into existing records without having to scan into loads of information on paper.</li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-12">
                    <div class="content-left">
                        <img src="../assets/images/product/fea.png" alt="#">
                    </div>
                </div>
               
            </div>
        </div>
    </section>
    
    <section class="about-us section mobile">
        <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-4 col-md-12 col-12">
                    <div class="content-left">
                        <img src="../assets/images/product/fea.png" alt="#">
                    </div>
                </div>
               <div class="col-lg-8 col-md-12 col-12">
                    <!-- content-1 start -->
                    <div class="content-right">
                        <h2>Essential Features in VMS</h2>
                        <div class="row">
                            <div class="col-lg-12 col-12">
                                <ul class="list">
                                    <li><i class="lni lni-checkmark-circle"></i>Customized visitor entry process, wherein different types of visitors can be treated and recognized based on their requirements for instance a general visitor and a courier delivery person would walk in for different reasons.</li>
                                    <li><i class="lni lni-checkmark-circle"></i>Notifications via text or voice messages. The software is enabled to notify the concerned employee in the company, the visitor requires visiting. This notification can be chosen on any preferable format.</li>
                                    <li><i class="lni lni-checkmark-circle"></i>Establishing a two-way communication system and message forwarding systems in place to help communicate with the software about the absence or presence of an employee.</li>
                                    <li><i class="lni lni-checkmark-circle"></i>Simplify paperwork, by signing into the VMS run software device, and easily submit NDA’s or look into existing records without having to scan into loads of information on paper.</li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
               
            </div>
        </div>
    </section>

    <section class="aboutdesc section">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12 col-12">
                    <div class="content-left">
                        <img src="../assets/images/product/imp.png" alt="#">
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 col-12">
                    <!-- content-1 start -->
                    <div class="content-right">
                        <h2>Importance of VMS</h2>
                        <div class="row">
                            <div class="col-lg-12 col-12">
                                <ul class="list">
                                    <li><i class="lni lni-checkmark-circle"></i>The first outlined importance of using VMS is the safety environment it provides keeping track of all the visitors to the company.</li>
                                    <li><i class="lni lni-checkmark-circle"></i>It can keep in check and control the purpose of visit, screen visitors and take over the period of their stay in the premises.</li>
                                    <li><i class="lni lni-checkmark-circle"></i>A job which is difficultly managed by a manned receptionist can be easily managed unmanned online and it can also store loads of information for future use without the restriction pen and paper currently does.</li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End About Area -->
</template>
<script>
export default {
    
}
</script>
<style >
/*======================================
    About Us CSS
========================================*/
.aboutdesc {
    padding: 50px 0px;
}

.aboutdesc .content-left {
  position: relative;
}

.aboutdesc .content-left img {
  width: 100%;
  border-radius: 5px;
}

.aboutdesc .content-right {
  padding-left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .aboutdesc .content-right {
    padding: 0px 30px;
    margin-top: 30px;
  }
  .desktop {
    display: none;
}
}
@media only screen and (min-width: 992px) and (max-width: 2561px) {
.mobile{
    display: none;
  }
}
.aboutdesc .content-right h2 {
  display: block;
  font-size: 32px;
  margin-bottom: 10px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aboutdesc .content-right h2 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 767px) {
  .aboutdesc .content-right h2 {
    font-size: 22px;
    line-height: 35px;
  }
}

.aboutdesc .content-right h3 {
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 8px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
}

.aboutdesc .content-right p {
  font-size: 15px;
  line-height: 25px;
  text-align: justify;
}

.aboutdesc .content-right .list {
  margin-top: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .aboutdesc .content-right .list {
    margin-top: 10px;
  }
}

.aboutdesc .content-right .list li {
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
  color: #777;
  padding-left: 25px;
  position: relative;
}

.aboutdesc .content-right .list li i {
  color: #723FE4;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
}

.aboutdesc .content-right .list li:last-child {
  margin: 0;
}


</style>