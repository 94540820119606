<template>
    <Header/>
    <Producthead/>
    <Calltoaction/>
    <Productdesc/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Producthead from '@/components/producthead.vue'
import Calltoaction from '@/components/calltoaction.vue'
import Productdesc from '@/components/productdesc.vue'


export default {
  components: {
     Header,
    Footer,
    Calltoaction,
    Productdesc,
    Producthead
  }
}
</script>